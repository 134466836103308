<template>
    <div id="data-view" class ="curContainer">    
        <el-row type="flex" justify="end" style="margin-top:5px;">                   
            <el-button type="primary" @click="onItemNew()" style="margin-right:10px;">新建通知服务</el-button>
        </el-row>    
      <el-form inline :model="searchForm" label-width="140px" style="margin-top: 30px;">
        <el-form-item label="关键字">
            <el-input v-model="searchForm.key" autocomplete="off"></el-input>
        </el-form-item>
        <el-button @click="onSearch" type="primary">搜索</el-button>
      </el-form>

        <common-table ref="tableObj"  :data="dataList" border 
                :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                style="width: 100%;margin-top: 10px;" stripe>                
                <el-table-column prop="clientName" align="center" label="客户" >
                    <template v-slot="scope">
                      <el-button type="text" @click="onEdit(scope.row)">{{scope.row.clientName}}</el-button>  
                    </template>
                </el-table-column>
                <el-table-column prop="frequency" align="center" label="发送频次" ></el-table-column>
                <el-table-column prop="sendDate" align="center" label="发送日期" ></el-table-column>
                <el-table-column prop="sendType" align="center" label="发送形式" ></el-table-column>
                <el-table-column prop="clients" align="center" label="客户接收人" ></el-table-column>
                <el-table-column prop="services" align="center" label="服务人员" ></el-table-column>          
                <el-table-column align="center" width="100px" label="状态" >
                    <template v-slot="scope">
                      <span :class="scope.row.isDel?'error':'ok'">{{scope.row.isDel?'停用':'正常'}}</span>  
                    </template>
              </el-table-column>    
      </common-table>

      <el-dialog title="编辑服务计划" v-model="editDialogVisible" width="60%" @open="open()"
                                              :modal-append-to-body='false'
                                              :close-on-click-modal='false'
                                              append-to-body>
            <el-form  ref="form" :model="editForm" label-width="138px" style="margin-top:20px;" label-position="right">
                <el-row>
                    <el-form-item label="客户名称"  >
                        <el-input  v-model="editForm.clientName" style="width:220px" placeholder="请输入客户名称"></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                <el-form-item label="发送频次"  >
                    <el-select v-model="editForm.frequency" clearable placeholder="请选择发送频次">
                    <el-option
                        v-for="item in frequencyList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发送日期"  >
                    <el-select v-model="editForm.sendDate" clearable placeholder="请选择发送日期">
                    <el-option
                        v-for="item in dateList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>
                </el-form-item>
                </el-row>
                <el-form-item label="发送形式"  >
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="editForm.checkedType" @change="handleCheckedCitiesChange">
                        <!-- <el-checkbox v-for="city in sendtypeList" :label="city" :key="city">{{city}}</el-checkbox> -->
                        <!-- <el-tooltip placement="right" effect="light">
                            <div slot="content" style="width: 500px;text-align: center" >
                                <img src="../../assets/img/danyao.jpg" style="width: 150px;height: 150px;">
                            </div>
                            <el-checkbox label="邮件">邮件</el-checkbox>
                        </el-tooltip> -->
                        <el-tooltip class="item" effect="dark" placement="left" >
                            <template v-slot:content> 
                                <div >
                                    <el-image :src="require('@/assets/img/email.jpg')" :preview-src-list="srcList1" style="width: 600px;height: 260px;"></el-image>
                                </div>
                            </template>
                            <el-checkbox label="邮件">邮件</el-checkbox>
                        </el-tooltip>

                        <el-tooltip class="item" effect="dark" placement="bottom" >
                            <template v-slot:content> 
                                <div >
                                    <el-image :src="require('@/assets/img/sms.jpg')" :preview-src-list="srcList2" style="width: 400px;height: 400px;"></el-image>    
                                </div>
                            </template>
                            <el-checkbox label="短信">短信</el-checkbox>
                        </el-tooltip>

                        <el-tooltip class="item" effect="dark" placement="right" >                            
                            <template v-slot:content> 
                                <div >
                                    <!-- <teleport to="body" > -->
                                    <el-image :src="require('@/assets/img/email.jpg')" :preview-src-list="srcList3" style="width: 600px;height: 260px;"></el-image> 
                                    <!-- </teleport>  -->
                                </div>
                            </template>
                            <el-checkbox label="微信">微信</el-checkbox>                            
                        </el-tooltip>                        
                       
                    </el-checkbox-group>
                </el-form-item>

                <!-- <el-form-item label="客户接收人"  >
                    <el-select v-model="editForm.clientId" clearable placeholder="请选择客户接收人">
                    <el-option
                        v-for="item in clientList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="客户接收人"  >
                    <el-checkbox :indeterminate="isIndeterminate3" v-model="checkAll3" @change="handleCheckAllChange3">全选</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="editForm.clientId" @change="handleCheckedCitiesChange3">
                        <el-checkbox v-for="city in clientList" :label="city" :key="city">{{city}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item label="服务人员"  >
                    <el-checkbox :indeterminate="isIndeterminate2" v-model="checkAll2" @change="handleCheckAllChange2">全选</el-checkbox>
                    <div style="margin: 15px 0;"></div>
                    <el-checkbox-group v-model="editForm.service" @change="handleCheckedCitiesChange2">
                        <el-checkbox v-for="city in serviceList" :label="city" :key="city">{{city}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

            </el-form>
            <el-divider></el-divider>
            <div style="display:flex;justify-content: center;">
                <el-button @click="editDialogVisible=false">返回</el-button>
                <el-button  @click="editDialogVisible=false" type="primary">确定</el-button>
            </div>

        </el-dialog>    
    </div>
</template>


<script>
import CommonTable from "@/components/CommonTable";
import { mapGetters } from "vuex";
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";

export default {
    components: {
        // vueQr
    },
    components: { CommonTable,MyUploadFileFormItem}, 
    computed: { ...mapGetters(["areaStationList"]) },

    data() {
        return {
            searchForm:{
                stationArray:[],
                key:'',
            },
            dataList:[{clientName:'董酒',frequency:'每天',sendDate:'--',sendTime:'09:00',sendType:'邮件/短信',clients:'张**；李**',services:'王**',isDel:false}
                ,{clientName:'金牌橱柜',frequency:'每周',sendDate:'周一',sendTime:'09:00',sendType:'邮件',clients:'赵**；钱**',services:'孙**',isDel:false}
                ,{clientName:'六个核桃',frequency:'每月',sendDate:'1号',sendTime:'12:00',sendType:'短信/微信',clients:'张**；李**',services:'蒋**',isDel:false}
                ,{clientName:'毛铺',frequency:'每周',sendDate:'周二',sendTime:'09:00',sendType:'短信',clients:'张**；吴**',services:'王**',isDel:true}
                ,{clientName:'火星人',frequency:'每月',sendDate:'5号',sendTime:'09:00',sendType:'邮件/短信/微信',clients:'刘**；张**',services:'陈**',isDel:false}],
            editForm:{
                checkedType:['邮件','短信'],
                service:['王**(销售)','蒋**(客服)'],
                clientId:['张**','李**'],
            },
            frequencyList:[{value:'每天',label:'每天'},{value:'每周',label:'每周'},{value:'每月',label:'每月'}],
            dateList:[{value:'周一',label:'周一'},{value:'周二',label:'周二'},{value:'周三',label:'周三'}
                ,{value:'周四',label:'周四'},{value:'周五',label:'周五'},{value:'周六',label:'周六'},{value:'周日',label:'周日'}],
            sendtypeList:['邮件','短信','微信'],
            checkAll: false,
            isIndeterminate: true,
            // clientList:[{value:'张**',label:'张**'},{value:'李**',label:'李**'}],
            clientList:['张**','李**'],
            checkAll3: false,
            isIndeterminate3: true,
            serviceList:['王**(销售)','孙**(客服)','蒋**(客服)'],
            checkAll2: false,
            isIndeterminate2: true,

            editDialogVisible:false,
            srcList1:[require('@/assets/img/email.jpg')],
            srcList2:[require('@/assets/img/sms.jpg')],
            srcList3:[require('@/assets/img/email.jpg')],

        };
    },
    watch: {},
    $route: {

    },
    async mounted(){

    },

    methods: {
      handleCheckAllChange(val) {
        this.editFrom.checkedType = val ? sendtypeList : [];
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.sendtypeList.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.sendtypeList.length;
      },

      handleCheckAllChange3(val) {
        this.editFrom.clientId = val ? clientList : [];
        this.isIndeterminate3 = false;
      },
      handleCheckedCitiesChange3(value) {
        let checkedCount = value.length;
        this.checkAll3 = checkedCount === this.clientList.length;
        this.isIndeterminate3 = checkedCount > 0 && checkedCount < this.clientList.length;
      },

      handleCheckAllChange2(val) {
        this.editFrom.service = val ? serviceList : [];
        this.isIndeterminate2 = false;
      },
      handleCheckedCitiesChange2(value) {
        let checkedCount = value.length;
        this.checkAll2 = checkedCount === this.serviceList.length;
        this.isIndeterminate2 = checkedCount > 0 && checkedCount < this.serviceList.length;
      },

      onItemNew(){
        this.editDialogVisible=true;
      },

       onEdit(item){
        this.editForm.clientName=item.clientName;
        this.editForm.frequency=item.frequency;
        this.editForm.sendDate=item.sendDate;
        this.editDialogVisible=true;
       },

       open() {
            // console.log("do open");
            this.$nextTick(() => {
                //  执行echarts方法
            });
        },

    }
};
</script>

<style lang="less">
  .curContainer {
    /* padding: 30px; */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}
</style>